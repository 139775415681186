import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
import { c, siteUrlBase, apiUrlBase } from './common';
import { ROLE } from './constants';

@inject(HttpClient, EventAggregator)
export class Security {
    tokenKey = 'lpfn.t';
    _authenticatedMemberIdKey = 'lpfn.mid';
    isAdminKey = 'lpfn.ia';
    originKey = 'lpfn.o';
    searchKey = 'lpfn.memberSearchKey';
    searchResultsKey = 'lpfn.memberSearchResultsKey';
	isAuthenticated = false;
	authenticatedMemberId;
	isAdmin = false;
    agentKey = 'lpfn.agent';
    agent = {};
    inRolesKey = 'lpfn.inroles';
    inRoles = [];
    SHADOWER_KEY = 'lpfn.shadower';
    shadower;
    isShadow = false;
    isAssistant = false;
    shadowUserId = undefined;
	token = '';
	loginErrorMessage = '';
	baseUiUrl = '';
	baseApiUrl = '';

	constructor(http, eventAggregator, i18n) {
	    this.http = http;
	    this._ea = eventAggregator;
	    this._i18n = i18n;
		this.baseUiUrl = siteUrlBase();
		this.baseApiUrl = apiUrlBase();

		this.http = http.configure(x => {
			x.withHeader("Accept", "application/json");
			x.withHeader("Content-Type", "application/json");
		});
	}

    profileName(agent) {
        if (agent) return agent.firstName;
        if (!this.agent) return '';
        console.log(this.agent);
        return this.agent.firstName;
    }

    setProfileName(id, firstName, lastName) {
        if (id !== this.authenticatedMemberId) return;
        this.agent.firstName = firstName;
        this.agent.lastName = lastName;
    }

    isTeam() {
        if (!this.agent) return false;
        return this.agent.isTeam;
    }

    isAgency() {
        if (!this.agent) return false;
        return this.agent.isAgency;
    }

    isMasterAgency() {
        if (!this.agent) return false;
        return this.agent.isMasterAgency;
    }

    teamType() {
        if (this.isMasterAgency()) return 'master-agency';
        if (this.isAgency()) return 'agency';
        return 'team';
    }

    /**
     * Determine whether the level should be displayed. Assistants cannot see their level, but can see the level of those below
     * @param {Guid} forMemberId - the ID of the member
     * @returns {boolean}
     */
    canSeeLevel(forMemberId) {
        if (!this.isAssistant) return true;
        if (forMemberId === this.authenticatedMemberId) return false;
        return true;
    }

    /**
     * Determine whether the commissions should be displayed
     * @returns {boolean}
     */
    canSeeCommissions() {
        if (this.isAssistant) return false;
        return this.isInRole(ROLE.BetaTester);
    }
    
    isInRole(value) {
        try {
            if (!value) return false;
            if (!this.inRoles || !this.inRoles.length) return false;
            let checkRoles = [];
            if (Array.isArray(value)) checkRoles = value;
            else checkRoles = value.indexOf(',') >= 0 ? value.split(',') : [value];
            let isInAtLeastOneRole = false;
            checkRoles.forEach(role => {
                if (this.inRoles.indexOf(role) >= 0) isInAtLeastOneRole = true;
            });
            return isInAtLeastOneRole;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    isLevel(level) {
        try {
            if (!this.agent || !this.agent.lifeLevel) return false;
            return this.agent.lifeLevel === level;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    isInMinimumLevel(minimumLevel) {
        try {
            // Levels must be number based: L01
            if (!this.agent || !this.agent.lifeLevel) return false;
            if (this.agent.lifeLevel === 'home-office') return true;
            const levelNumber = parseInt(this.agent.lifeLevel.replace(/\D/g, ''));
            const minimumLevelNumber = parseInt(minimumLevel.replace(/\D/g, ''));
            return levelNumber >= minimumLevelNumber;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    signUpLink(forAgent) {
        let url = `${this.baseUiUrl}/#sign-up/`;
        if (forAgent) {
            url += forAgent.email;
        }
        if (!this.agent) return null;
        if (!forAgent) {
            if (!this.agent || !this.agent.email) return '';
            url += this.agent.email;
        }
        return url;
    }

    openShareLink(forAgent, zIndex) {
        if (forAgent) {
            const agentSignUpLink = this.signUpLink(forAgent);
            const agentShareLink = c.Helpers.replaceVariables(this, `https://form.jotform.com/211016101203025?agentName[first]=${encodeURIComponent(forAgent.firstName)}&agentName[last]=${encodeURIComponent(forAgent.lastName)}&agentEmail=${encodeURIComponent(forAgent.email)}&agentPhone[full]=${encodeURIComponent(forAgent.cellPhone)}&baseshopName[first]=${encodeURIComponent(forAgent.agencyFirstName)}&baseshopName[last]=${encodeURIComponent(forAgent.agencyLastName)}&baseshopEmail=${encodeURIComponent(forAgent.agencyEmail)}&baseshopPhone[full]=${encodeURIComponent(forAgent.agencyCell)}&agentLink=${encodeURIComponent(agentSignUpLink)}`, true);
            this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: agentShareLink, zIndex });
            return;
        }
        if (!this.agent) return null;
        const shareLink = c.Helpers.replaceVariables(this, `https://form.jotform.com/211016101203025?agentName[first]={{firstName}}&agentName[last]={{lastName}}&agentEmail={{email}}&agentPhone[full]={{cell}}&baseshopName[first]={{agencyFirstName}}&baseshopName[last]={{agencyLastName}}&baseshopEmail={{agencyEmail}}&baseshopPhone[full]={{agencyCell}}&agentLink={{signUpLink}}`, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: shareLink, zIndex });
    }

    canAccessAdmin() {
        if (this.isAdmin) return true;
        return this.isInRole(this.accessAdminRoles());
    }

    accessAdminRoles() {
        return [ROLE.Admin, ROLE.AgentAdmin, ROLE.WebsiteAdmin, ROLE.PolicyReviewer, ROLE.PolicyAdmin, ROLE.LeadManager];
    }

    showAdminToggle() {
        if (this.isAdmin) return true;
        return this.isInRole([ROLE.Admin, ROLE.CarrierAdmin, ROLE.WebsiteAdmin, ROLE.Translator, ROLE.ResourceAdmin, ROLE.TrainingAdmin]);
    }

    canEditContent() {
        return this.isInRole([ROLE.Admin, ROLE.WebsiteAdmin, ROLE.Translator]);
    }

    canHaveAgentSite() {
        return true;
	}
	canShareLink() {
	    return true;
	}
	canAccessCorporateEvents() {
	    return true;
	}
	canBePromoted() {
	    return true;
	}
	canAccessBranding() {
	    return true;
	}
	canOnlyCreateLevelLists() {
	    return false;
	}
    isAuthenticatedMember(memberId) {
        if (!this.authenticatedMemberId || !memberId) return false;
        return this.authenticatedMemberId.toLowerCase() === memberId.toLowerCase();
    }

	loadState() {
		this.setToken(this.fromLocalStore(this.tokenKey), false);

		let authenticatedMemberIdValue = this.fromLocalStore(this._authenticatedMemberIdKey);
		if (authenticatedMemberIdValue !== null) this.authenticatedMemberId = authenticatedMemberIdValue;

		var isAdminValue = this.fromLocalStore(this.isAdminKey);
		if (isAdminValue !== null && isAdminValue === true || isAdminValue === false) {
			this.isAdmin = isAdminValue;
		}
		var agentValue = this.fromLocalStore(this.agentKey);
		if (agentValue) {
		    this.agent = JSON.parse(agentValue);
            if (this.agent) this.agent.id = this.authenticatedMemberId;
            if (this.agent && !this.agent.fullName) this.agent.fullName = `${this.agent.firstName} ${this.agent.lastName}`;
            if (this.agent && !this.agent.directUplineFirstName) {
                this.agent.directUplineFirstName = '';
                this.agent.directUplineLastName = '';
                this.agent.directUplineEmail = '';
                this.agent.directUplineCell = '';
            }
            if (this.agent && !this.agent.teamFirstName) {
                this.agent.teamFirstName = '';
                this.agent.teamLastName = '';
                this.agent.teamEmail = '';
                this.agent.teamCell = '';
            }
            if (this.agent && !this.agent.agencyFirstName) {
                this.agent.agencyFirstName = '';
                this.agent.agencyLastName = '';
                this.agent.agencyEmail = '';
                this.agent.agencyCell = '';
            }
            if (this.agent) {
                if (this.agent.isBaseshop) { this.agent.isAgency = this.agent.isBaseshop; delete this.agent.isBaseshop; }
                if (this.agent.baseshopFirstName) { this.agent.agencyFirstName = this.agent.baseshopFirstName; delete this.agent.baseshopFirstName; }
                if (this.agent.baseshopLastName) { this.agent.agencyLastName = this.agent.baseshopLastName; delete this.agent.baseshopLastName; }
                if (this.agent.baseshopEmail) { this.agent.agencyEmail = this.agent.baseshopEmail; delete this.agent.baseshopEmail; }
                if (this.agent.baseshopCell) { this.agent.agencyCell = this.agent.baseshopCell; delete this.agent.baseshopCell; }
            }
	    }
        const inRolesValue = this.fromLocalStore(this.inRolesKey);
        this.inRoles = inRolesValue ? JSON.parse(inRolesValue) : [];

        const shadowerValue = this.fromLocalStore(this.SHADOWER_KEY);
        this.shadower = shadowerValue ? JSON.parse(shadowerValue) : undefined;
        this.shadowUserId = this.shadower ? this.shadower.userId : '';
        this.isShadow = this.shadower ? true : false;
        this.isAssistant = this.shadower ? this.shadower.isAssistant : false;

		this.setBodyClasses();
	}

	setOrigin(origin) {
		localStorage.setItem(this.originKey, origin);
	}
	getOrigin() {
		var origin = this.fromLocalStore(this.originKey);
		localStorage.removeItem(this.originKey);
		return origin;
	}

	async login(username, password) {
        try {
			const response = await this.http.post(this.baseApiUrl + '/Token', 'grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password));
            var authResponse = new AuthenticationResponse(response);
            if (!authResponse.isAuthenticated) {
                this.setToken('', true);
                this.loginErrorMessage = authResponse.errorDescription;
                return LoginResult.InvalidLogin;
            }

            this.setToken(authResponse.token, true);

            this.authenticatedMemberId = authResponse.authenticatedMemberId;
            localStorage.setItem(this._authenticatedMemberIdKey, this.authenticatedMemberId)

            this.isAdmin = authResponse.isAdmin;
            localStorage.setItem(this.isAdminKey, this.isAdmin);

            this.agent = authResponse.agent;
            if (this.agent && !this.agent.fullName) this.agent.fullName = `${this.agent.firstName} ${this.agent.lastName}`;
            localStorage.setItem(this.agentKey, JSON.stringify(this.agent));

            this.inRoles = authResponse.inRoles;
            localStorage.setItem(this.inRolesKey, JSON.stringify(this.inRoles));

            this.shadower = authResponse.shadower;
            if (this.shadower) localStorage.setItem(this.SHADOWER_KEY, JSON.stringify(this.shadower));
            this.shadowUserId = this.shadower ? this.shadower.userId : '';
            this.isShadow = this.shadower ? true : false;
            this.isAssistant = this.shadower ? this.shadower.isAssistant : false;
    
            this.setBodyClasses();

            this._ea.publish(c.EventKeys.loginAuthenticationUpdated, { authenticated: true });
            return LoginResult.Authenticated;
        } catch (err) {
            this.setToken("", true);
            var authResponse = new AuthenticationResponse(err);
            this.loginErrorMessage = authResponse.errorDescription;
            this.authenticatedMemberId = null;
            this.isAdmin = false;
            this.agent = {};
            this.inRoles = [];
            localStorage.setItem(this.isAdminKey, 'false');
            if (authResponse.error === 'invalid_terms') {
                return LoginResult.TermsNotAggreedTo;
            }
            if (authResponse.error === 'invalid_registration') {
                return LoginResult.InvalidRegistrationNoDirectUpline;
            }
            return LoginResult.InvalidLogin;
        }
	}

	logout() {
	    this.setToken("", true);
	    this.authenticatedMemberId = null;
	    this.isAdmin = false;
		localStorage.removeItem(this.isAdminKey);
		localStorage.removeItem(this.searchKey);
		localStorage.removeItem(this.searchResultsKey);
		localStorage.removeItem(this.agentKey);
		localStorage.removeItem(this.inRolesKey);
		localStorage.removeItem(this.SHADOWER_KEY);
		$('body').removeClass('admin-tools-on');
		$('body').removeClass('members');
		this._ea.publish(c.EventKeys.loginAuthenticationUpdated, { authenticated: false });
	}

	setToken(token, setLocalStore) {
		if (token === null) token = "";
		this.token = token;
		this.isAuthenticated = token.length !== 0;
		if (setLocalStore === true && !this.isAuthenticated) {
			localStorage.removeItem(this.tokenKey);
		} else if (setLocalStore === true) {
		    localStorage.setItem(this.tokenKey, token);
		}

		this.setBodyClasses();
	}

	setBodyClasses() {
		if (this.isAuthenticated) {
			$("body").addClass("members");
		} else {
			$("body").removeClass("members");
		}
	}

	fromLocalStore(key) {
		try {
		    var lsValue = localStorage.getItem(key);
		    if (lsValue === 'true') return true;
		    if (lsValue === 'false') return false;
		    return lsValue;
		} catch (err) {
			return null;
		}
	}

    completeOnboarding() {
        if (!this.agent) return;
        this.agent.onboardingComplete = true;
        localStorage.setItem(this.agentKey, JSON.stringify(this.agent));
        this._ea.publish(c.EventKeys.menu.changed, { area: 'members' });
        this._ea.publish(c.EventKeys.onboarding.completed);
    }
}

export class AuthenticationResponse {
	isAuthenticated = false;
	token = '';
	error = '';
	errorDescription = '';
	authenticatedMemberId;
	isAdmin = false;
    agent = {};
    inRoles = [];
    shadower = undefined;

	constructor(httpResponse) {
		var data = JSON.parse(httpResponse.response);
		if (httpResponse.statusCode !== 200) {
			this.isAuthenticated = false;
			this.error = data.error;
			this.errorDescription = data.error_description;
		} else if (!data.access_token) {
			this.isAuthenticated = false;
			this.error = "Error";
			this.errorDescription = "Unknown Error";
		} else {
			this.isAuthenticated = true;
			this.token = data.access_token;
			this.authenticatedMemberId = data.memberId;
			this.isAdmin = data.isAdmin === "true" ? true : false;
            this.agent = JSON.parse(data.agentJson);
            if (this.agent && !this.agent.fullName) this.agent.fullName = `${this.agent.firstName} ${this.agent.lastName}`;
            if (this.agent) this.agent.id = this.authenticatedMemberId;
            this.inRoles = JSON.parse(data.rolesJson || '[]');
            this.shadower = data.shadower ? JSON.parse(data.shadower) : null;
        }
	}
}

class LoginResults {
	constructor() {
		//console.log('LoginResults ctor');
		this.Authenticated = 1;
		this.InvalidLogin = 2;
		this.TermsNotAggreedTo = 3;
		this.InvalidRegistrationNoDirectUpline = 4;
	}
}

var _LoginResults = new LoginResults();
export var LoginResult = _LoginResults;
